import * as Sentry from '@sentry/browser';
import { useBreakpointIndex } from '@theme-ui/match-media';
// eslint-disable-next-line import/no-extraneous-dependencies
import debounce from 'debounce';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import Footer from '../../components/Footer/Footer';
import { useGlobalContext } from '../../components/GlobalContextProvider/GlobalContextProvider';
import Header from '../../components/Header/Header';
import ToTopButton from '../../components/ToTopButton/ToTopButton';
import { DETAILED_PAGES } from '../../consts/component-types';
import { MOBILE_BREAKPOINT_INDEX } from '../../consts/style-consts';
import { DDNC_NEWS_URL, FULL_SEARCH_ROUTES } from '../../consts/urls';
import useAfterRouteUpdate from '../../hooks/useAfterRouteUpdate';
import { kebabToTitle } from '../../utils/caseconverters';
import { matchUrl, strictMathUrl } from '../../utils/matchUrl';
import removeQueryParameters from '../../utils/removeQueryParameters';
import NavigationPage from '../NavigationPage/NavigationPage';
import SearchPage from '../SearchPage';
import {
  ArchivedMessage,
  BasePageContainer,
  ChildrenWrapper,
} from './BasePage.styled';

// eslint-disable-next-line import/no-unresolved
const { hri } = require('human-readable-ids');

const { publicRuntimeConfig } = getConfig();

const WagtailUserbar = dynamic(() => import('../../components/WagtailUserbar'));

class CustomReportError extends Error {
  constructor(message, name) {
    super(message);
    this.name = name;
  }
}

const BasePage = ({
  children,
  seo,
  summary,
  subcategoriesItems,
  isArchived,
  componentName,
  parentItm,
  parentItems,
  title,
  restricted,
  restrictionType,
}) => {
  const {
    seoHtmlTitle,
    seoMetaDescription,
    seoOgTitle,
    seoOgDescription,
    seoOgUrl,
    seoOgImage,
    seoOgType,
    seoTwitterTitle,
    seoTwitterDescription,
    seoTwitterUrl,
    seoTwitterImage,
    seoMetaRobots,
    canonicalLink,
  } = seo;

  const router = useRouter();
  const breakpointIndex = useBreakpointIndex();
  const {
    user,
    isSearchActive,
    setHeaderRef,
    isNavActive,
    setNavItems,
    setSearchValue,
    setIsSearchActive,
    setSubcategoryFilterValue,
    setContentView,
    contentView,
  } = useGlobalContext();

  useAfterRouteUpdate();

  useEffect(() => {
    const isContentPage =
      componentName &&
      matchUrl(componentName, [
        'ArmedForcesPage',
        'WeaponDetailPage',
        'MilitaryOrganizationPage',
        'NewsPage',
        'ReportPage',
      ]) &&
      restricted !== undefined;

    // useSendIsCroppedToGTM for content pages
    if (!isContentPage) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pageView',
        contentView,
        restrictionType,
        componentName,
      });
    }

    Sentry.addBreadcrumb({
      category: 'started',
      message: `Version id ${publicRuntimeConfig?.version}`,
      level: 'info',
      data: { version: publicRuntimeConfig?.version },
    });

    const sendUserReportedErrorBody = () => {
      const reportId = hri.random();
      const currentDate = new Date().toISOString().split('T')[0]; // Get the current date in YYYY-MM-DD format

      Sentry.withScope(function (scope) {
        scope.setTag('report_id', reportId);
        scope.setLevel('critical');
        scope.setTag('origin', 'userreportederror');
        // Retrieve user context from the scope
        const { email } = scope.getUser() || {};

        // Check if user is defined before accessing its properties
        const userEmail = email || 'unknown';

        // Custom message with user info and date
        const customMessage = `Customer ${userEmail} report from ${currentDate}`;

        // Create a custom error object
        const customError = new CustomReportError(
          customMessage,
          'Customer Report',
        );

        // Capture the custom error
        const eventId = Sentry.captureException(customError, user);

        // Show the Sentry report dialog
        Sentry.showReportDialog({
          eventId,
          ...(user && {
            user: {
              email: user.email,
              name: user.givenName,
            },
          }),
          subtitle2: `If you’d like to help, tell us what happened below. Also you can share the ID ${reportId} with the Military Periscope support team.`,
        });
      });
    };

    const sendUserReportedError = (event) => {
      if (navigator.userAgent.toUpperCase().includes('MAC')) {
        if (event.metaKey && event.altKey && event.key === '0') {
          sendUserReportedErrorBody();
        }
      } else if (event.ctrlKey && event.altKey && event.key === 'd') {
        sendUserReportedErrorBody();
      }
    };

    const debouncedSentryDebug = debounce(sendUserReportedError, 1000);
    document.addEventListener('keydown', debouncedSentryDebug);

    if (window && window.dataLayer) {
      // useSendIsCroppedToGTM for content pages
      if (isContentPage) {
        return;
      }

      window.dataLayer.push({
        event: 'pageFullyLoaded',
      });
    }

    return () => {
      document.removeEventListener('keydown', debouncedSentryDebug);
    };
  }, []);

  const header = useRef();
  useEffect(() => {
    setHeaderRef(header?.current);
  }, [header]);

  useEffect(() => {
    if (!parentItems) {
      return;
    }

    setNavItems([...parentItems, { title, url: seo.seoOgUrl }]);
  }, [title]);

  useEffect(() => {
    if (restricted === undefined) {
      setContentView('notContent');
      return;
    }

    if (restricted) {
      setContentView('gated');
    } else {
      setContentView('full');
    }
  }, [restricted]);

  useEffect(() => {
    if (!router.query.search && !router.query.search2) {
      return;
    }

    setIsSearchActive(true);
    setSearchValue(router.query.search || router.query.search2);
  }, [router.query]);

  useEffect(() => {
    const currentSubcategory = subcategoriesItems
      ? subcategoriesItems.find(
          (el) => el.value === router.asPath.split('/')[2],
        )
      : null;

    setSubcategoryFilterValue(currentSubcategory);
  }, [router.asPath]);

  const isFullBar =
    (matchUrl(router.asPath, FULL_SEARCH_ROUTES) &&
      !strictMathUrl(componentName, DETAILED_PAGES)) ||
    breakpointIndex <= MOBILE_BREAKPOINT_INDEX ||
    isSearchActive;

  const initialTag = matchUrl(router.asPath, [DDNC_NEWS_URL])
    ? 'news'
    : matchUrl(router.asPath, FULL_SEARCH_ROUTES)
    ? kebabToTitle(removeQueryParameters(router.asPath).split('/')[1])
    : null;

  return (
    <>
      <Head>
        <title>{seoHtmlTitle}</title>
        <link rel='icon' href='/favicon.ico' />
        {!!seoMetaDescription && (
          <meta name='description' content={seoMetaDescription} />
        )}
        {!!seoOgTitle && <meta property='og:title' content={seoOgTitle} />}
        {!!(seoOgDescription || summary) && (
          <meta
            property='og:description'
            content={summary || seoOgDescription}
          />
        )}
        {!!seoOgUrl && <meta property='og:url' content={seoOgUrl} />}
        {!!seoOgImage && <meta property='og:image' content={seoOgImage} />}
        {!!seoOgType && <meta property='og:type' content={seoOgType} />}
        {!!seoTwitterTitle && (
          <meta property='twitter:title' content={seoTwitterTitle} />
        )}
        {!!seoTwitterDescription && (
          <meta
            property='twitter:description'
            content={seoTwitterDescription}
          />
        )}
        {!!seoTwitterUrl && (
          <meta property='twitter:url' content={seoTwitterUrl} />
        )}
        {!!seoTwitterImage && (
          <meta property='twitter:image' content={seoTwitterImage} />
        )}
        <meta name='robots' content={seoMetaRobots} />
        {!!canonicalLink && <link rel='canonical' href={canonicalLink} />}
        {/* Start of HubSpot Embed Code */}
        <script
          type='text/javascript'
          id='hs-script-loader'
          async
          defer
          src='//js.hs-scripts.com/21197975.js?businessUnitId=237239'
        />
        {/* End of HubSpot Embed Code */}
      </Head>
      <BasePageContainer>
        <Header
          ref={header}
          title={title || null}
          initialTag={initialTag}
          componentName={componentName}
          parentItm={parentItm || null}
          subcategories={subcategoriesItems}
          isFullBar={isFullBar}
        />
        <ChildrenWrapper>
          {isNavActive ? (
            <NavigationPage />
          ) : isSearchActive ? (
            <SearchPage />
          ) : (
            <>
              {isArchived && (
                <ArchivedMessage>----- HISTORICAL RECORD -----</ArchivedMessage>
              )}
              {children}
            </>
          )}
          <ToTopButton />
        </ChildrenWrapper>
        {!isNavActive && <Footer />}
      </BasePageContainer>
    </>
  );
};

BasePage.defaultProps = {
  children: {},
  seo: {},
  wagtailUserbar: {},
};

BasePage.propTypes = {
  children: PropTypes.node,
  seo: PropTypes.shape({
    seoHtmlTitle: PropTypes.string,
    seoMetaDescription: PropTypes.string,
    seoOgTitle: PropTypes.string,
    seoOgDescription: PropTypes.string,
    seoOgUrl: PropTypes.string,
    seoTwitterTitle: PropTypes.string,
    seoMetaRobots: PropTypes.string,
  }),
  wagtailUserbar: PropTypes.shape({
    html: PropTypes.string,
  }),
};

export default BasePage;
